import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import classes from "./MainNavigation.module.css";

function MainNavigation(props) {
  const location = useLocation();

  function isActive(path) {
    if (location.pathname === path) {
      return classes.activePath;
    } else {
      return classes.notActivePath;
    }
  }

  return (
    <header className={classes.header}>
      <div className={classes.logo}>Oxana Paskida</div>
      <nav>
        <ul>
          <li>
            <Link to="/" className={isActive("/")}>
              About
            </Link>
          </li>
          <li>
            <Link to="/portfolio" className={isActive("/portfolio")}>
              Portfolio
            </Link>
          </li>
          <li>
            <Link to="/certificates" className={isActive("/certificates")}>
              Certificates
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
