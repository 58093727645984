import classes from "./GeneralItem.module.css";

function GeneralItem(props) {
  return (
    <div className={classes.general__item}>
      <svg>
        <use xlinkHref={props.icon_link}></use>
      </svg>
      <p>{props.contact}</p>
    </div>
  );
}

export default GeneralItem;
