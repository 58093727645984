import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import General from "./components/Pages/General/General";
import LoadingSpinner from "./components/UI/LoadingSpinner";

import "./App.css";

const Portfolio = React.lazy(() =>
  import("./components/Pages/Portfolio/Portfolio")
);
const Certificates = React.lazy(() =>
  import("./components/Pages/Certificates/Certificates")
);

function App() {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        <Routes>
          <Route exact path="/" element={<General />} />

          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/certificates" element={<Certificates />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
