import classes from "./MainFooter.module.css";

function MainFooter() {
  return (
    <footer className={classes.footer}>
      <p>
        &copy; Copyright 2022 by Oxana Paskida. Implemented by Oxana Paskida.
        This website is done using React.
      </p>
    </footer>
  );
}

export default MainFooter;
