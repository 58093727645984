import Button from "../../UI/Button";
import GeneralItem from "./Generaltem";

import contacts from "../../../database/contacts.json";

import classes from "./General.module.css";

function General() {
  return (
    <section className={classes.general}>
      <ul className={classes.general__skills}>
        <li>
          <h1>Oxana Paskida</h1>
        </li>
        <li>
          <h2>Front End (Web) developer</h2>
        </li>
        <li>HTML</li>
        <li>CSS (incl. SASS, Grid, Flexbox)</li>
        <li>Javascript - React and Vue</li>
        <li>Python (Django)</li>
        <li>
          <a
            href={require("../../../img/OxanaPaskidaCV.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            <Button>Open CV</Button>
          </a>
        </li>
      </ul>

      <div className={classes.general__contacts}>
        <h2 className={classes.general__heading}>Contacts</h2>
        <div className={classes.general__items}>
          {contacts.map((contact) => (
            <GeneralItem
              key={contact.id}
              id={contact.id}
              icon_link={contact.icon_link}
              contact={contact.contact}
            />
          ))}
        </div>
      </div>

      <div className={classes.general__languages}>
        <h2 className={classes.general__heading}>Languages</h2>
        <div className={classes.general__lang__container}>
          <div className={classes.general__lang}>
            <picture>
              <source
                className={classes.general__lang__img}
                srcSet={"./img/img_lang/png/gb.png"}
                alt="English"
              />
              <img
                className={classes.general__lang__img}
                src={"./img/img_lang/webp/gb.webp"}
                alt="English"
              />
            </picture>
            <label>Fluent English</label>
          </div>
          <div className={classes.general__lang}>
            <picture>
              <source
                className={classes.general__lang__img}
                srcSet={"./img/img_lang/png/es.png"}
                alt="Spanish"
              />
              <img
                className={classes.general__lang__img}
                src={"./img/img_lang/webp/es.webp"}
                alt="Spanish"
              />
            </picture>
            <label>Fluent Spanish</label>
          </div>
          <div className={classes.general__lang}>
            <picture>
              <source
                className={classes.general__lang__img}
                srcSet={"./img/img_lang/png/de.png"}
                alt="German"
              />
              <img
                className={classes.general__lang__img}
                src={"./img/img_lang/webp/de.webp"}
                alt="German"
              />
            </picture>
            <label>Intermediate German</label>
          </div>
          <div className={classes.general__lang}>
            <picture>
              <source
                className={classes.general__lang__img}
                srcSet={"./img/img_lang/png/ru.png"}
                alt="Russian"
              />
              <img
                className={classes.general__lang__img}
                src={"./img/img_lang/webp/ru.webp"}
                alt="Russian"
              />
            </picture>
            <label>Native Russian</label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default General;
